



import React from 'react';

const Facility = () => {

        const facility = [
        {
            id: 1,
            image: '/adoni/facility/facility1.jpg',
            title: " Material storage   after testing in Nabl accredited Labs storage by color coding ",
            description: '',
        },
        {
            id: 2,
            image: '/adoni/facility/facility2.jpg',
            title: "TOOLS AND GAUGES",
            description: '',
        },
        {
            id: 3,
            image: '/adoni/facility/facility6.jpg',
            title: "Measurement of surface rougness by Zeiss Handysurf 35 ",
            description: '',
        },
        {
            id: 4,
            image: '/adoni/facility/facility4.jpg',
            title: "HARDNESS TESTING MACHINE            ",
            description: '',
        },
        {
            id: 5,
            image: '/adoni/facility/facility5.jpg',
            title: "AIR GAUGES            ",
            description: 'Each cylinder bore size checked on air gauge.',
        },
        {
            id: 6,
            image: '/adoni/facility/facility3.jpg',
            title: "INHOUSE HEAT TREATMENT ",
           
            description: '',
        },
        {
            id: 7,
            image: '/adoni/facility/facility7.jpg',
            title: "OIL FILLING  ",
            description: 'Exact amount of oil filled to achieve adequate damping',

        },
        {
            id: 8,
            image: '/adoni/facility/facility8.jpg',
            title: "CYCLIC TESTING  ",
            description: ' Continious cyclic testing to check leakage .',
        },
      
        {
            id: 9,
            image: '/adoni/facility/facility10.jpg',
            title: "HYDRAULIC TESTING for Large bore shock absorbers ",
            description: '',
        },
        {
            id: 10,
            image: '/adoni/facility/facility9.jpg',
            title: "IMPACT TESTING   ",
            description: ' Impact testing to know stroke and damping force and data recorded digitally.',
        },
        {
            id: 11,
            image: '/adoni/facility/facility11.jpg',
            title: "FINE LAPPING ",
            description: '',
        },
        {
            id: 12,
            image: '/adoni/facility/facility12.jpg',
           
            title: "CNC MACHINE ",
            description: '',
        },
        {
            id: 13,
            image: '/adoni/facility/facility13.jpg',
            title: "ASSEMBLY PACKING AREA",
            description: '',
        },
        {
            id: 14,
            image: '/adoni/facility/facility14.jpg',
            title: "Crane Buffer cycling Test Rig ",
            description: '',
        },
        {
            id: 15,
            image: '/adoni/facility/facility15.jpg',
            title: " Dimension measuring system ",
            description: '',
        },
        
    ]


  return (
    <div className="md:max-w-7xl mx-auto px-4 mt-20 py-8">
      <h2 className="text-2xl font-semibold mb-4">Facilities of our workshop</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
       {facility.map((item , key)=>{
        return (
            <div className="nature" key={key}>
            <div className="content border-2 border-blue-100 hover:border-blue-200 cursor-pointer rounded-md bg-white p-5">
              <img src={item.image} alt="Mountains" className="w-full h-80" />
              <h4 className="text-xl font-semibold mt-5">{item.title}</h4>
              <p className="text-sm text-gray-600">{item.description}</p>
            </div>
          </div>
        )
       })}
       
      </div>
    </div>
  );
};

export default Facility;
