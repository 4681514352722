const corporateData = [
    {
      id: 1,
      image: '/adoni/products/p1.jpeg',
      title: "SB Series Buffer",
      description: 'Services',
    },
    {
      id: 2,
      image: '/adoni/products/p2.jpeg',
      title: "EI Series Heavy Industry Shock Absorbers",
      description: 'Services',
    },
    {
      id: 3,
      image: '/adoni/products/p3.jpeg',
      title: 'EI Series Heavy Industry Shock Absorbers',
      description: 'Services',
    },
    {
      id: 4,
      image: '/adoni/products/p4.jpeg',
      title: "EI Series Heavy Industry Shock Absorbers",
      description: 'Services',
    },
    {
      id: 5,
      image: '/adoni/products/p5.jpeg',
      title: "EI Series Heavy Industry Shock Absorbers",
      description: 'Services',
    },
    {
      id: 6,
      image: '/adoni/products/p7.jpeg',
      title: 'EI Series Heavy Industry Shock Absorbers',
      description: 'Services',
    },
    {
      id: 7,
      image: '/adoni/products/p8.jpeg',
      title: "EI Series Heavy Industry Shock Absorbers",
      description: 'Services',
    },
    {
      id: 8,
      image: '/adoni/products/p9.jpeg',
      title: "EI Series Heavy Industry Shock Absorbers",
      description: 'Services',
    },
    {
      id: 9,
      image: '/adoni/products/p10.jpeg',
      title: 'EI Series Heavy Industry Shock Absorbers',
      description: 'Services',
    },
 
 
 
  ];
  
  export default corporateData;
  