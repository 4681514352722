const corporateData = [
    {
      id: 1,
      image: '/adoni/cards/cards1.jpg',
      title: "Industrial Shock Absorber",
      description: 'Services',
    },
    {
      id: 2,
      image: '/adoni/cards/cards2.jpg',
      title: "Linear Motion Slider",
      description: 'Services',
    },
    {
      id: 3,
      image: '/adoni/cards/cards3.jpg',
      title: 'Endless 3D Reality',
      description: 'Services',
    },
    // {
    //   id: 4,
    //   image: '/image/Metaverse/img4.jpg',
    //   title: 'Sports',
    //   description: 'Services',
    // },
  ];
  
  export default corporateData;
  