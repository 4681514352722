import React, { useState, useEffect } from "react";
import styled from "styled-components";






export default function About() {

  const [whatsAppVisible, setWhatsAppVisible] = useState(false);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {


    const whatsAppTimer = setTimeout(() => {
      setWhatsAppVisible(true);
    }, 5000);

    return () => {
      clearTimeout(whatsAppTimer);
    };
  }, []);

  const handleCloseWhatsApp = () => {
    setWhatsAppVisible(false);
  };
  const handleClosePopup = () => {
    setShowImage(false);
  };


  return (
    <>


      <div class=" bg-gray-50  lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 md:mt-20 px-1">
        <div class="text-center md:mx-28  mb-20">
          <p class="font-normal text-sm leading-3 text-indigo-700  hover:text-indigo-800 cursor-pointer">About us</p>
          <h2 class="  font-bold text-gray-800  lg:text-4xl text-3xl lg:leading-10 leading-9 mt-2">Welcome to <span className="text-blue-800">ADONI TECH</span> the Pioneers in Crane Buffer Solutions.</h2>
          <p class="font-normal text-base leading-6 text-gray-600  mt-6">Founded in 2007, ADONI TECH has established itself as a leader in the crane buffer manufacturing industry. Our journey began with a simple mission: to enhance the safety and efficiency of crane operations worldwide. With over  17 experience, we have not only achieved that goal but have set new standards in the crane buffer technology field.</p>
        </div>

        <div class="container my-24 mx-auto md:px-6">

          <section class="mb-32">

            <div class="flex xs:gap-10 flex-wrap items-center">
              {/* <div class=" w-full  md:px-6  lg:w-5/12">
                <div class="relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg "
                  data-te-ripple-init data-te-ripple-color="light">
                  <img src="adoni/about/curve-brochure-removebg-preview.jpg" class="w-full" />
                  <a href="#!">
                    <div
                      class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,0%,0.4)] bg-fixed">
                      <div class="flex h-full items-center justify-center">
                        <div class="px-6 py-12 text-center text-white md:px-12">
                          <h3 class="mb-6 text-2xl font-bold uppercase">
                            Trust is Here
                          </h3>
                          <p class="hover:bg-blue-900 transition easy-in py-6 rounded-md px-4 ">
                            At ADONI TECH we specialize in designing and manufacturing high-quality crane buffers that play a crucial role in protecting cranes and structures from impacts during operation. Our products are engineered to absorb the kinetic energy generated by moving cranes, thereby preventing damage and ensuring the safety of operators and infrastructure.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="relative overflow-hidden bg-cover bg-no-repeat">
                      <div
                        class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                      </div>
                    </div>
                  </a>
                </div>
              </div> */}

              <div className="border border-gray-600">
                <img src="adoni/certificate/isoCertificate.jpg" className=" h-[600px] mx-auto"></img>
              </div>

              <div class="w-full shrink-0 grow-0 basis-auto md:px-6 lg:w-7/12">
                <div class=" flex ">
                  <div class="shrink-0">
                    <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" class="h-6 w-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4 grow">
                    <p class="mb-1 text-xl font-bold">Our Commitment to Quality and Innovation</p>
                    <p class="text-neutral-800 ">
                      Quality and innovation are the cornerstones of our business. We are committed to using only the best materials and the latest technology in the design and production of our crane buffers.

                    </p>
                  </div>
                </div>

                <div class="mt-12 flex">
                  <div class="shrink-0">
                    <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" class="h-6 w-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4 grow">
                    <p class="mb-1 text-xl font-bold">Tailored Solutions for Every Need</p>
                    <p class="text-neutral-800 ">
                      We understand that every crane operation is unique, which is why we offer customized buffer solutions to fit the specific needs and requirements of our clients.
                    </p>
                  </div>
                </div>

                <div class="mt-12 flex">
                  <div class="shrink-0">
                    <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" class="h-6 w-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4 grow">
                    <p class="mb-1 text-xl font-bold">A Global Network of Trust</p>
                    <p class="text-neutral-800 ">
                      Over the years, ADONI TECH has built a robust network of clients and partners around the globe. We are proud to have our crane buffers installed in some of the world’s most demanding environments.
                    </p>
                  </div>
                </div>

                <div class="flex mt-12">
                  <div class="shrink-0">
                    <div class="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" class="h-6 w-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4 grow">
                    <p class="mb-1 text-xl font-bold">Safety as a Priority</p>
                    <p class="text-neutral-800 ">
                      Safety is not just a part of our products; it’s ingrained in our ethos. We believe in creating a safe working environment not just for crane operators but for everyone involved in crane operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>



        {/* <div class="lg:mt-16 sm:mt-12 mt-16 flex lg:flex-row justify-between flex-col lg:gap-8 gap-12">
          <div class="w-full xl:w-5/12 lg:w-6/12">
            <h2 class="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 dark:text-white">Our Vision</h2>
            <p class="font-normal text-base leading-6 text-gray-600 dark:text-white mt-4"> "To pioneer innovative solutions in safety and efficiency across diverse industries, ensuring seamless integration of advanced technologies to safeguard lives, assets, and operations worldwide."</p>
          </div>
          <div class="lg:flex items-center w-full lg:w-1/2">
            <img class="lg:block hidden h-[280px] w-full" src="adoni/about/abouts1.jpeg" alt="people discussing on board" />
            <img class="lg:hidden sm:block hidden w-full h-3/4" src="https://i.ibb.co/ZLgK3NQ/Group-788.png" alt="people discussing on board" />
            <img class="sm:hidden block w-full" src="adoni/hero/home.png" alt="people discussing on board" />
          </div>
        </div> */}
        {/* <div class="lg:mt-16 sm:mt-12 mt-16 flex lg:flex-row justify-between flex-col lg:gap-8 gap-12">

          <div class="lg:flex items-center w-full lg:w-1/2">
            <img class="lg:block hidden w-full" src="adoni/about/abouts3.jpeg" alt="people discussing on board" />
            <img class="lg:hidden sm:block hidden w-full h-3/4" src="https://i.ibb.co/ZLgK3NQ/Group-788.png" alt="people discussing on board" />
            <img class="sm:hidden block w-full" src="https://i.ibb.co/9g2R7Xr/Group-803.png" alt="people discussing on board" />
          </div>
          <div class="w-full xl:w-5/12 lg:w-6/12">
            <h2 class="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 dark:text-white">Our Mission</h2>
            <p class="font-normal text-base leading-6 text-gray-600 dark:text-white mt-4">"Our mission is to deliver superior quality products and services that exceed industry standards, empowering our clients with reliable solutions for transformer explosion prevention, fire extinguishing systems, aircraft arrestor barrier systems, industrial automation, and power generation. Through continuous innovation, exceptional craftsmanship, and unwavering dedication, we strive to enhance safety, productivity, and sustainability for our customers and communities."</p>
          </div>
        </div> */}
        <section className="p-4 lg:p-8 bg-gray-100 ">
          <div className="container mx-auto space-y-12">
            <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row">
              <img src="adoni/about/abouts3.jpeg" alt="" className="h-80  aspect-video" />
              <div className="flex flex-col justify-center flex-1 p-6 ">
                <span className="text-xs uppercase ">Join, us</span>
                <h3 className="text-3xl font-bold">Our Vision</h3>
                <p className="my-6 ">
                  Pioneering cutting-edge safety and efficiency solutions across diverse industries, we prioritize seamless integration of advanced technologies. Our mission is to safeguard lives, assets, and operations globally. With a commitment to innovation, we lead the way in implementing state-of-the-art measures that ensure the highest standards of safety and efficiency, fostering trust and reliability in every aspect of our solutions for clients worldwide.</p>
              </div>
            </div>
            <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row-reverse">
              <img src="adoni/about/abouts1.jpeg" alt="" className="h-80  aspect-video" />
              <div className="flex flex-col justify-center flex-1 p-6 ">
                <span className="text-xs uppercase ">our thinking</span>
                <h3 className="text-3xl font-bold">Our Mission</h3>
                <p className="my-6 ">Our mission is to deliver superior quality products and services that exceed industry standards, empowering our clients with reliable solutions for transformer explosion prevention, fire extinguishing systems, aircraft arrestor barrier systems, industrial automation, and power generation. Through continuous innovation, exceptional craftsmanship, and unwavering dedication, we strive to enhance safety, productivity, and sustainability for our customers and communities.</p>
              </div>
            </div>

          </div>
        </section>

        {/* <div>
          <img src="adoni/certificate/isoCertificate.jpg" className=" h-[600px] mx-auto"></img>
        </div> */}

      </div>

    </>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


