// import React ,{useState} from "react";
// import styled from "styled-components";
// import ProjectBox from "../Elements/ProjectBox";
// import AddImage2 from "../../assets/screen/screes1.jpg";
// import AddImage3 from "../../assets/screen/screes2.jpg";
// import AddImage4 from "../../assets/screen/scees4.jpg";
// import Clouser from "./Clouser";
// import ClientSlider from "../Elements/ClientSlider";

// export default function Projects() {
//   const [showModal, setShowModal] = useState(false);
//   const [imageSrc, setImageSrc] = useState("");

//   const openModal = (src) => {
//     setImageSrc(src);
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setImageSrc("");
//   };



//   return (
//     <Wrapper id="projects" className="">

//       <div className="whiteBg">
//         <div className="container">
//           <HeaderInfo>
//             <h1 className="playfair-display-font1 pb-4 font40 extraBold">Some Of Our Best Product</h1>
//             <p className="font13">

//               <br />
//             </p>
//           </HeaderInfo>
//           <div className="row textCenter">
//             <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
//               <ProjectBox
//                 img="adoni/product/eiproduct.jpg"
//                 title="EI Series Heavy Industry Shock Absorbers"
//                 text="very strong energy absorption capacity for single shock, particularly suitable for safety stop of large or super large equipments."
//                 action={() => openModal(AddImage2)} 
//               />
//             </div>
//             <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
//               <ProjectBox
//                 img="adoni/product/edproduct.jpg"
//                 title="ED Series Heavy Duty Shock Absorbers"
//                 text="Characteristics of the El Series products include a nitrogen-charged return system that allows for soft deceleration and positive return in a maintenance- free package."
//                 action={() => openModal(AddImage3)} 
//               />
//             </div>
//             <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
//               <ProjectBox
//                 img="adoni/product/sbproduct.jpg"
//                 title="SB Series Buffer"
//                 text="Provides reliable and consistent performance under various operating conditions, ensuring equipment safety and stability. and also : Suitable for a wide range of industrial and commercial applications where shock absorption is critical."
//                 action={() => openModal(AddImage4)} 
//               />
//             </div>
//           </div>

//           {/* <div className="row flexCenter">
//             <div style={{ margin: "50px 0", width: "200px" }}>
//               <FullButton title="Load More" action={() => alert("clicked")} />
//             </div>
//           </div> */}
//         </div>
//       </div>
//       <div className="mt-20 w-full" style={{backgroundImage: `url('/adoni/bgclouser2.jpg')`}}>
//         <div className="container">


//           <Clouser />
//           {/* <ImgWrapper className="flexCenter">
//                   <img className="radius8" src={AddImage2} alt="add" />
//                 </ImgWrapper> */}

//           {/* <AddRight>
//               <h4 className="font15 semiBold">A few words about company</h4>
//               <h2 className="font40 playfair-display-font1 pb-2 extraBold">Let's Discover The World Together </h2>
//               <p className=" lora-about">
//                 Miniature-india Is A Specialised Tour Company That Offers An Exquisite National And International Travel Experience. Where We Transform Dreams Into
//                 Unforgettable Journeys. Established With A Passion For Travel And A Commitment To Providing Unparalleled Experiences, We Are A Premier Tours And Travel
//                 Company Dedicated To Creating Memories That Last A Lifetime. The Vast Chain Of Networks Across The Globe With Southeast Asia, Middle East & Africa,
//                 CIS Countries, Europe, United States Of America And United Kingdom Ensures A Luxurious Worldwide Exposure
//               </p>
//               <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
//                 <div style={{ width: "190px" }}>
//                   <FullButton title="Get Started" action={() => alert("clicked")} />
//                 </div>
//                 <div style={{ width: "190px", marginLeft: "15px" }}>
//                   <FullButton title="Contact Us" action={() => alert("clicked")} border />
//                 </div>
//               </ButtonsRow>
//             </AddRight> */}


//         </div>
//       </div>
//       <ModalWrapper show={showModal} onClick={closeModal}>
//         <ModalContent onClick={(e) => e.stopPropagation()}>
//           <CloseButton onClick={closeModal}>X</CloseButton>
//           <ModalImage className="" src={imageSrc} alt="Popup Image" />
//         </ModalContent>
//       </ModalWrapper>
//     </Wrapper>
//   );
// }

// const Wrapper = styled.section`
//   width: 100%;
// `;
// const HeaderInfo = styled.div`
//   @media (max-width: 860px) {
//     text-align: center;
//   }
// `;
// const Advertising = styled.div`
//   padding: 100px 0;
//   margin: 100px 0;
//   position: relative;
//   @media (max-width: 1160px) {
//     padding: 60px 0 40px 0;
//   }
//   @media (max-width: 860px) {
//     flex-direction: column;
//     padding: 0 0 30px 0;
//     margin: 80px 0 0px 0;
//   }
// `;
// const ButtonsRow = styled.div`
//   @media (max-width: 860px) {
//     justify-content: space-between;
//   }
// `;
// const AddLeft = styled.div`
//   position: relative;
//   width: 50%;
//   p {
//     max-width: 475px;
//   }
//   @media (max-width: 860px) {
//     width: 80%;
//     order: 2;
//     text-align: center;
//     h2 {
//       line-height: 3rem;
//       margin: 15px 0;
//     }
//     p {
//       margin: 0 auto;
//     }
//   }
// `;
// const AddRight = styled.div`
//   width: 50%;
//   @media (max-width: 860px) {
//     width: 80%;
//     order: 2;
//   }
// `;
// const AddLeftInner = styled.div`
//   width: 100%;
//   position: absolute;
//   top: -300px;
//   left: 0;
//   @media (max-width: 1190px) {
//     top: -250px;
//   }
//   @media (max-width: 920px) {
//     top: -200px;
//   }
//   @media (max-width: 860px) {
//     order: 1;
//     position: relative;
//     top: -60px;
//     left: 0;
//   }
// `;


// const ModalWrapper = styled.div`
//   display: ${(props) => (props.show ? "block" : "none")};
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 999;
// `;

// const ModalContent = styled.div`
//   position: relative;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-color: #fff;
//   max-width: 80%;
//   max-height: 80%;
//   overflow-y: auto;
//   padding: 20px;
// `;

// const ModalImage = styled.img`
//   width: 100%;
//   height: auto;

// `;

// const CloseButton = styled.button`
//   position: absolute;
//   top: 10px;
//   right: 30px;
//   background-color: transparent;
//   border: none;
//   cursor: pointer;
//   font-size: 40px;

// `;

// const ImgWrapper = styled.div`
//   width: 100%;
//   padding: 0 15%;
//   img {
//     width: 100%;
//     height: auto;
//   }
//   @media (max-width: 400px) {
//     padding: 0;
//   }
// `;


import React, { useState } from "react";
import styled from "styled-components";
import ProjectBox from "../Elements/ProjectBox";
import AddImage2 from "../../assets/screen/screes1.jpg";
import AddImage3 from "../../assets/screen/screes2.jpg";
import AddImage4 from "../../assets/screen/scees4.jpg";
import Clouser from "./Clouser";
import ClientSlider from "../Elements/ClientSlider";

export default function Projects() {
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const openModal = (src) => {
    setImageSrc(src);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setImageSrc("");
  };

  return (
    <div>

      <div class="container  mx-auto md:px-6">

        <section class="mb-32 mt-20 text-center">
          {/* <h2 class="mb-12 pb-4 text-center text-3xl font-bold">
            heavy duty buffer for crane
          </h2> */}
          <h3 className='text-center mb-5 uppercase' >Corporate Services</h3>
          <h1 className="text-center text-4xl font-bold mb-12"> Heavy Duty Buffer For Crane</h1>

          <div class="grid gap-6 lg:grid-cols-3 xl:gap-x-12">
            <div class="mb-6 lg:mb-0">
              <div
                class="relative block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                <div class="flex">
                  <div
                    class="relative mx-4 -mt-4 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg dark:shadow-black/20"
                    data-te-ripple-init data-te-ripple-color="light">
                    <img src="adoni/product/eiproduct.jpg" class="w-full h-[280px]" />
                    <a href="#!">
                      <div
                        class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]">
                      </div>
                    </a>
                  </div>
                </div>
                <div class="p-6">
                  <h5 class="mb-3 text-lg font-bold">EI Series Heavy Industry Shock Absorbers</h5>

                  {/* <p class="mb-4 text-neutral-500 dark:text-neutral-300">
                    <small>Published <u>13.01.2022</u> by
                      <a href="#!">Anna Maria Doe</a></small>
                  </p> */}
                  <p class="mb-4 pb-2">
                  Discover our EI Series heavy-duty shock absorbers engineered for the demands of heavy industry, providing reliable performance and enhanced safety in challenging environments
                  </p>
                  <button data-te-ripple-init data-te-ripple-color="light" onClick={() => openModal(AddImage2)}
                    class="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]  ">Read
                    more</button>
                </div>
              </div>
            </div>

            <div class="mb-6 lg:mb-0">
              <div
                class="relative block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                <div class="flex">
                  <div
                    class="relative mx-4 -mt-4 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg "
                    data-te-ripple-init data-te-ripple-color="light">
                    <img src="adoni/product/edproduct.jpg" class="w-full h-[280px]" />
                    <a href="#!">
                      <div
                        class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]">
                      </div>
                    </a>
                  </div>
                </div>
                <div class="p-6">
                  <h5 class="mb-3 text-lg font-bold">ED Series Heavy Industry Shock Absorbers</h5>

                  <p class="mb-4 pb-2">
                  Explore our ED Series heavy-duty shock absorbers, meticulously designed for robustness and durability in demanding industrial applications, ensuring optimal performance and safety under challenging conditions.
                  </p>
                  <button onClick={() => openModal(AddImage3)} data-te-ripple-init data-te-ripple-color="light"
                    class="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">Read
                    more</button>
                </div>
              </div>
            </div>

            <div class="mb-0">
              <div
                class="relative block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
                <div class="flex">
                  <div
                    class="relative mx-4 -mt-4 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg "
                    data-te-ripple-init data-te-ripple-color="light">
                    <img src="adoni/product/sbproduct.jpg" class="w-full h-[280px]" />
                    <div >
                      <button onClick={() => openModal(AddImage4)}
                        class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]">
                      </button>
                    </div>
                  </div>
                </div>
                <div class="px-6  pt-6 pb-6">
                  <h5 class="mb-3 text-lg font-bold">SB Series Spring Buffer</h5>

                  <p class="mb-4 pb-2">
                  Introducing our SB Series spring buffers, expertly crafted to deliver precise energy absorption in diverse industrial environments. Enhance safety and operational efficiency with these reliable solutions tailored to your specific applications.
                  </p>
                  <button onClick={() => openModal(AddImage4)} data-te-ripple-init data-te-ripple-color="light"
                    class="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] 0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] [0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">Read
                    more</button>
                </div>
              </div>
            </div>
          </div>

        </section>

      </div>
      <ModalWrapper show={showModal} onClick={closeModal}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={closeModal}>X</CloseButton>
          <ModalImage className="" src={imageSrc} alt="Popup Image" />
        </ModalContent>
      </ModalWrapper>
    </div>
  )
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;


const ModalWrapper = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalContent = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  padding: 20px;
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;

`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 40px;

`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
