// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './bannerClouser.css';

// const images = [
//     { id: 1, src: '/adoni/banner/banners2.jpg', tiltle: "   Heavy Duty Shock Absorber" },

//     { id: 2, src: '/adoni/banner/adonibanner.jpg' },
// ];

// const BannerClouser = () => {
//     const settings = {
//         dots: true,
//         fade: true,
//         infinite: true,
//         speed: 2000,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         arrows: false,
//         autoplay: true,
//         autoplaySpeed: 1500,
//     };

//     return (

//         <div className='w-full md:mt-20 '>
//             <Slider {...settings}>
//                 {images.map(image => (
//                     <div  className="">
//                         {/* <div className="slide-background opacity-1" style={{ backgroundImage: `url(${image.src})` }}>
//                             <div
//                                 class="absolute xs:bg-transparent xs:from-black/70 xs:to-white/10 ltr:xs:bg-gradient-to-r rtl:xs:bg-gradient-to-l inset-0 sm:bg-transparent sm:from-black/30 sm:to-white/10 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"
//                             ></div>

//                             <div className="relative xs:mt-10 mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8 slide-content">
//                                 <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
//                                     <h1 className="text-4xl  font-semibold text-white libre-baskerville-regular sm:text-5xl">
//                                         Crane Buffer
//                                         <strong className="block md:font-normal libre-baskerville-regular md:pt-5 text-white text-rose-700">  </strong>
//                                     </h1>

//                                     <p className="mt-4  md:text-[15px] max-w-lg text-white sm:text-xl/relaxed">
//                                         Focuses on cost-effective energy absorption and vibration isolation solution
//                                     </p>

//                                     <div className="mt-8  flex flex-wrap gap-4 text-center">
//                                         <a  href="https://calculation.cranebuffer.com/"  className="w-full xs:mx-auto transition duration-500 ease-in-out  xs:w-1/2 rounded hover:bg-white bg-rose-600 px-12 py-3 text-sm font-medium text-white  hover:text-rose-600 sm:w-auto" >     Go To Crane Buffer Calculator </a>
//                                     </div>
//                                 </div>
//                             </div>

//                         </div> */}
//                         <section key={image.id} class="py-28 bg-black   bg-no-repeat bg-center bg-cover bg-fixed overflow-hidden" style={{ backgroundImage: `url(${image.src})` }}>
//                             <div class="container  px-4 mx-auto">
//                                 <div class="px-12 slide-content rounded-lg pt-12 pb-9 md:max-w-xl bg-black bg-opacity-80 rounded-4xl" style={{ backdropFilter: 'blur(10px)' }}>

//                                     <p class="mb-7 font-sans max-w-max px-3  text-sm text-white font-semibold uppercase border border-gray-700 rounded-md"></p>
//                                     <h2 class="mb-4 text-6xl md:text-4xl text-white font-bold font-heading tracking-px-n leading-tight">Crane Buffer</h2>
//                                     <p class="mb-11 text-lg text-gray-400 font-medium leading-normal">                                        Focuses on cost-effective energy absorption and vibration isolation solution.</p>
//                                     <a class="inline-flex border px-4 py-4 flex-wrap items-center text-white hover:text-gray-200" href="https://calculation.cranebuffer.com">
//                                         <span class="mr-2  font-semibold leading-normal">Go to Crane Buffer Calculator</span>
//                                         <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                             <path d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
//                                         </svg>
//                                     </a>

//                                 </div>
//                             </div>
//                         </section>
//                     </div>
//                 ))}
//             </Slider>
//         </div>


//     );
// };

// export default BannerClouser;


import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './bannerClouser.css';
import { Link } from 'react-router-dom';

const images = [
    { id: 1, src: '/adoni/banner/banner3.jpg', title1: "   Heavy Duty ", title2: "Crane Buffer" },

    { id: 2, src: '/adoni/banner/1 (5).jpg', title1: "  Heavy Duty ", title2: "Crane Buffer" },
    { id: 3, src: '/adoni/banner/2 (4).jpg', title1: "  Heavy Duty ", title2: "Crane Buffer" },
];

const BannerClouser = () => {
    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (

        <div className='w-full md:mt-20 '>
            <Slider {...settings}>
                {images.map(image => (
                    <div className="">
                        <section class="px-3 py-5  lg:py-10" style={{ backgroundImage: `url(/adoni/banner/banner3.jpg)` }}>
                            <div class="grid lg:grid-cols-2 items-center justify-items-center gap-5">
                                <div class="container  px-4 mx-auto">
                                    <div class="px-12 slide-content rounded-lg pt-12 pb-9 md:max-w-xl bg-black bg-opacity-80 rounded-4xl" style={{ backdropFilter: 'blur(10px)' }}>

                                        <p class="mb-7 font-sans max-w-max px-3  text-sm text-white font-semibold uppercase border border-gray-700 rounded-md"></p>
                                        <h2 class="mb-4 text-6xl md:text-4xl text-white font-bold font-heading tracking-px-n leading-tight">Crane Buffer</h2>
                                        <p class="mb-11 text-lg text-gray-400 font-medium leading-normal"> Focuses on cost-effective energy absorption and vibration isolation solution.</p>
                                        <a class="inline-flex border px-4 py-4 flex-wrap items-center text-white hover:text-gray-200" href="https://calculation.cranebuffer.com">
                                            <span class="mr-2  font-semibold leading-normal">Go to Crane Buffer Calculator</span>
                                            <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </a>

                                    </div>
                                </div>
                                <div class="order-1 lg:order-2">
                                    <img class="h-80 w-80 lg:w-[500px] lg:h-[500px]" src={image.src} alt="" />
                                </div>
                            </div>
                        </section>



                        {/* <section key={image.id} class="py-28 bg-black   bg-no-repeat bg-center bg-cover bg-fixed overflow-hidden" style={{ backgroundImage: `url(${image.src})` }}>
                            <div class="container  px-4 mx-auto">
                                <div class="px-12 slide-content rounded-lg pt-12 pb-9 md:max-w-xl bg-black bg-opacity-80 rounded-4xl" style={{ backdropFilter: 'blur(10px)' }}>
                                   
                                    <p class="mb-7 font-sans max-w-max px-3  text-sm text-white font-semibold uppercase border border-gray-700 rounded-md"></p>
                                    <h2 class="mb-4 text-6xl md:text-4xl text-white font-bold font-heading tracking-px-n leading-tight">Crane Buffer</h2>
                                    <p class="mb-11 text-lg text-gray-400 font-medium leading-normal">                                        Focuses on cost-effective energy absorption and vibration isolation solution.</p>
                                    <a class="inline-flex border px-4 py-4 flex-wrap items-center text-white hover:text-gray-200" href="https://calculation.cranebuffer.com">
                                        <span class="mr-2  font-semibold leading-normal">Go to Crane Buffer Calculator</span>
                                        <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </a>
                                 
                                </div>
                            </div>
                        </section> */}
                    </div>
                ))}
            </Slider>
        </div>


    );
};

export default BannerClouser;

